@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@700&family=Inter:wght@600&family=Poppins:wght@400;500;600&family=Roboto:wght@400;500;700&display=swap');

/* font-family: 'DM Sans', sans-serif;     700 
font-family: 'Inter', sans-serif;     600
font-family: 'Poppins', sans-serif;       400,500,600
font-family: 'Roboto', sans-serif;        400,500,700*/

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Inter', 'Roboto', sans-serif;
  background-color: #07081D;
  color: white;
}

.hover{
scale: 1;
transition: all 0.3s;
}

.hover:hover{
  // background-color: var(--e-global-color-uicore_secondary) !important;
  // padding: 18px 5px;
  // border-radius: 7px;
  scale: 1.1;
  color: white !important;
}

.green-color {
  color: #1EC067 !important;
}

.yellow-color {
  color: #FABB04 !important;
}

.blue-color {
  color: #4285F4 !important;
}

.red-color {

  color: #F03F3E !important;

}

.pink-color {
  color: #E73BB1 !important;
}


.purple-color {
  color: #e946da !important;
}

.orange-color {
  color: #FF7222 !important;
}


.mouseover {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5rem;

  border-radius: 7px;

}

.bgshade {
  background-color: #23233B;
  padding: 15px;
}

.boardIcon {
  font-size: 2.5rem;
  padding: 10px;
}

.mouseover:hover {
  color: #E946DA;
}

.center {
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  
}


a {
  text-decoration: none !important;
}






//Crypto assets table 
.crypto-assets-section {
  max-width: 1283px;
  width: 90%;
  margin-top: 80px;
  margin-left: auto;
  margin-right: auto;
  color: #e6e6e8;
  padding-bottom: 200px;


  font-family: 'Inter';
  font-weight: 600;
  letter-spacing: 0em;
  font-style: normal;
}

.crypto-assets-section .section-title {
  font-size: 36px;
  text-align: center;
  text-transform: uppercase;
  color: #e6e6e8;
  line-height: 1.2;
}

.bold {
  font-weight: 700;

}

.align-center {
  text-align: center;
}

.crypto-assets-section .section-name {
  color: #e946da;
  font-size: 16px;
  margin-bottom: 10px;
  letter-spacing: .4em;
}

// .table {
//   overflow-x: scroll;
// }

.crypto-assets-table {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  margin-top: 46px;
  height: auto;
  border-collapse: collapse;
  background-color: transparent !important;
}

.table> :not(caption)>*>* {
  background-color: transparent !important;
  color: #e6e6e8 !important;
}

.crypto-assets-table thead {
  position: relative;
  box-shadow: 0px 0px 5px #efefef;
}

.crypto-assets-table thead::after {
  content: '';
  width: 100%;
  color: red;
  border: 0.4px solid #d3d0d0;
  position: absolute;
  z-index: 1200;
}

.crypto-assets-table,
.crypto-assets-table td,
.crypto-assets-table th {
  border: 1px solid #626D7D70;
  width: auto;
}




.crypto-assets-table tr th {
  font-size: 1rem;
  padding: 0.5rem 0.6rem;
  text-align: center;
  color: #e6e6e8;
  font-weight: bold;
}

.crypto-assets-table tr td {

  font-size: 0.9rem;
  font-family: "Inter";
  text-align: center;
  color: #e6e6e8;
  padding-left: 15px;
  padding-right: 15px;
  text-align: center;
  font-weight: 500;
  width: auto;
   max-width: 250px;
  line-height: 1rem;
  text-align: right;
}

.td-css {
  font-weight: 300;
  max-width: 100px !important;
  text-align: center !important;
}


.sticky-rank,
.sticky-name {
  position: absolute;
  position: sticky;
  border: 1px solid #626D7D70;
  top: 0;
  background-color: transparent;
  transition: all 0.3s;
  z-index: 1;
}

.sticky-rank {

  min-width: 30px;
  left: -1px;
  background:#07081D !important;
  z-index: 2;
  border: 1px solid #626D7D70;

}

.sticky-name {
  left: 50px;
  width: fit-content;
  min-width: 220px;


}



.asset-box {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}

.asset-image {
  width: 25px;
  margin-right: 10px;
}

.assets-info {
  text-align: left;
}

.asset-name {
  font-size: 0.90rem;
  text-align: left;
  font-weight: 600;
  margin: 0;

}

.asset-short {
  font-size: 0.8rem;
  text-align: left;
  font-weight: 400;
  margin: 0;
  color: #d3d0d0;
}


// border to email box in subscription section
.border-none {
  border: none;
}


.section-name {
  color: #e946da !important;
  font-size: 16px;
  margin-bottom: 10px;
  letter-spacing: .4em;
}

.uicore-hca:hover {
  cursor: pointer;
}

.uicore-hca {
  padding-top: 0.6rem;
}






.w-70{
  width: 65%;
}

.hero-title{
  font-size: 3.3rem ;
  
}


@media only screen and (max-width: 767px) {


  .bgshade {

    margin-right: auto;
  }

  .crypto-assets-section {
    width: 97%;
  }

  .min-width-90{
    min-width: 90% !important;
    margin-left: 5%;
  }

  .text-center{
    text-align: center;
  }

  .w-70{
    width: 100%;
    min-width: 300px;
  }

  .hero-title{
    font-size: 40px ;
  }
  
  
}








.blur {
  filter: brightness(50%) blur(5px);
}

.none {
  display: none !important;
}


.modalBg{
  position:fixed;
  width: 100%;
  height:100%;
  top:0;
  left:0;
  z-index:1000;
  backdrop-filter: blur(5px);
  background: rgba(0, 0, 0, 0.3);

}

// Modal design

.modaldesign {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #23242F;
  border-radius: 20px;
  max-width: 960px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  z-index: 1000;
  box-shadow: 0px 0px 8px white;
}

.children {
  position: relative;
  width: 100%;
}

#table_children * {
  background: transparent !important;
}

.close-button {
  font-size: 30px;
  font-size: 'poppins', sans-serif;
  font-weight: 400;
  color: white;
  position: absolute;
  right: 25px;
  margin-top: 30px;
  text-align: center;
  background: transparent;
  cursor: pointer;
  width: 45px;

}

.coin-info {
  width: 90%;
  margin: 50px 20px 10px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

.coin-info h3 {
  font-size: 3rem;
  font-family: 'Inter', sans-serif;
  font-weight: 800;
  color: #d3d0d0;
}

#coin-compliance {
  border: 1px solid #23233B;
  background-color: rgba(63, 61, 61, 0.253);
  padding: 10px 15px;
  border-radius: 15px;
}

.coin-image {
  width: 50px;
  padding-top: 10px;
  margin-right: 20px;

}

.crypto-title {
  display: flex;
}


.coin-reason {
  width: 90%;
  margin: 0px 20px 20px;
  color: #e6e6e8;
  text-align: justify;
  font-size: 1rem;
  font-size: 'Inter';
  font-weight: 400;

}


@media only screen and (max-width:1024px) {

  .modaldesign {
    border-radius: 30.043px;
  }


  .close-button {
    font-size: 20px;
    font-size: 'poppins', sans-serif;
    font-weight: 300;
    color: white;
    position: absolute;
    right: 25px;
    margin-top: 30px;
    text-align: center;
    background: transparent;
    cursor: pointer;
    width: 25px;

  }
}


@media only screen and (max-width:768px) {

  .modaldesign {

    border-radius: 20.043px;
    max-width: 500px;
    min-width: 350px;
  }



}


// Loader
.loader {
  border: 2px solid #f3f3f3;
  border-top: 2px solid #e946da;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}


// PoPup
.Popup {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  backdrop-filter: blur(5px);
  display: flex;
  justify-content: center;
  align-items: center;
}

.coming_soon {
  position: relative;
  background: #23242F;
  border-radius: 25.043px;
  max-width: 500px;
  width: 100%;
  height: 100px;
  z-index: 1000;
  box-shadow: 0px 0px 8px white;
  display: flex;
  justify-content: center;
  align-items: center;
}

.coming_soon .btn {
  position: absolute;
  right: 10px;
  top: 0px;
  color: white;
  font-size: 32px;
}

.coming_soon h3 {
  margin: 0;
  font-size: 32px;
}

.toast {
  background: "#1ab394 !important"
}

.tv-lightweight-charts table td {
  border: none !important;
}
