$primary: '#E946DA';
$secondary: #0A3161;
$danger: #FF4949;
$info: #EAF0FE;
$dark: #07071c;
$light: #e6e6e8;
$success: #00A389;
$warning: #FFAB2D;

@tailwind base;
@tailwind components;
@tailwind utilities;


@font-face {
  font-family: 'Inter';
  src: url('./assets/fonts/Inter.ttf') format('woff2-variations');
  font-weight: 125 950;
  font-stretch: 75% 125%;
  font-style: normal;
}

*[role=button],
.pointer {
  cursor: pointer;
  user-select: none;
}

*:hover {
  transition: all .1s ease;
}

*:focus {
  box-shadow: none;
  outline: none;
}

*:focus-visible {
  outline: 0;
}

*::-webkit-scrollbar {
  width: 0.4em;
  height: 0.3em;
}

*::-webkit-scrollbar-thumb {
  background-color: #21252932;

  &:hover {
    background-color: #00000092;
  }
}

body {
  background-color: $dark !important;
  color: $light !important;
  font-family: 'Inter';
  margin: 0;
  overflow: auto;
  overflow: overlay;
}

.search-bar {
  display: flex;
  justify-content: start;
}

.search-bar input[type="text"] {
  width: 100%;
  max-width: 300px;
  padding: 10px 20px;
  font-size: 16px;
  color: #fff; /* Text color for dark mode */
  background-color: #151a21; /* Background color for dark mode */
  border: 1px solid #555; /* Darker border color */
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.6); /* Slightly darker shadow */
  transition: all 0.3s ease;
}

.search-bar input[type="text"]::placeholder {
  color: #888; /* Placeholder color remains the same */
}

.search-bar input[type="text"]:focus {
  border-color: #E73BB1; /* or your primary color */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.8); /* Darker shadow on focus */
  outline: none;
}

ul {
  list-style: none;
  margin-bottom: 0 !important;
  padding-left: 0 !important;
}

a {
  color: inherit !important;
  transition: all .3s ease;

  &:hover {
    color: $primary !important;
  }
}

.button-app {
  color: #EAF0FE !important;
  background-color: #E946DA !important;
  font-weight: 500 !important;
  transition: all .3s ease !important;

  &:hover {
      background-color: #9625e0 !important;
  }
}

  .button-selected {
    color:#EAF0FE  !important;
    background-color: #9625e0 !important;
    font-weight: 500 !important;
    transition: all .3s ease !important;
  
    &:hover {
        background-color: #E946DA !important;
    }
}